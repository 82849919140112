// Here you can add other styles
.fs-4 {
  font-size: 2.0736rem;
}
input[type="file" i] {
  margin-bottom: 1px;
}
.error {
  color: red;
}
.custom-badge {
  padding: 10px;
  font-size: 11px;
}
.pagination {
  display: inline-block;
}
.pagination button {
  color: white;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  background: #3c4c64;
}
.pagination button :disabled{
    /* background: gray; */
    opacity: 0.65;
    cursor: not-allowed;
}
.card-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.discount-badge {
  position: absolute;
  top: 2%;
  right: 2%;
}
.text-green {
  color: green;
}
.text-red {
  color: red;
}
.fa-49 {
  font-size: 49px;
}
.title {
  font-size: 20px;
}
.not-selected,
.selected {
  .image_delete_btn,
  .image-detail {
    display: none;
  }
}
.not-selected:hover {
  .image_delete_btn {
    display: block;
    position: absolute;
    right: 12px;
    top: 7px;
    z-index: 99;
  }
  .image-detail {
    opacity: 6;
    display: block;
    transition: all 0.4s ease 0s;
    /* padding-top: 123px; */
    position: absolute;
    z-index: 99;
    text-align: center;
    width: 100%;
    background: white;
    bottom: 0;
    color: black;
  }
}

.slide {
  img {
    height: 285.3px;
  }
}

.invoice {
  width: 80%;
  margin: auto;
  padding: 24px;
  img {
    width: 16%;
    height: 28%;
    margin: auto 0;
  }
}

#invoice_table {
  border-collapse: collapse;
  width: 100%;
}

#invoice_table td,
#invoice_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#invoice_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#invoice_table tr:hover {
  background-color: #ddd;
}

#invoice_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
.metrics_widgets {
  .card-body {
    width: 100%;
    display: flex;
    flex-direction: revert;
  }
}
.order-info-icon{
  position: absolute;
  z-index: 999;
  right: 6%;
  top: 4%;
  cursor: pointer;
}
.pending-order-box{
  position: relative;
}
