/*-----------------------------------------------
|   Table
-----------------------------------------------*/
// .table{
//   tfoot > tr > th:first-child,
//   thead > tr > th:first-child,
//   tr th:first-child,
//   tr td:first-child
//   { padding-left: $card-spacer-x; }
// }
.react-bootstrap-table table {
  table-layout: auto !important;
}
.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
  background: #3c4b64 !important;
}
.white-space-nowrap {
  width: 1px;
  white-space: nowrap;
}
// th{ font-weight: $font-weight-semi-bold; }
td .dropdown-toggle:after {
  display: none !important;
}
.table-dashboard {
  overflow: hidden;
  th {
    border-bottom-width: 1px !important;
  }
}
// .table-bordered td, .table-bordered th {
//   border: 1px solid $gray-200;
// }

.react-bootstrap-table th[data-row-selection] {
    padding: 6px 12px;
}
.table-bordered thead th, .table-bordered thead td {
  word-break: break-word;
  
}
.react-bootstrap-table-pagination{
  margin: 0 !important;
}
.table-bordered td{
  word-break: break-word;
}
