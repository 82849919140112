.dropzoneTextStyle {
    text-align: center;
    top: 25%;
    position: relative;
  }
  
  .dropzoneParagraph {
    font-size: 16px
  }
  
  .dropZone {
    position: relative;
    width: 100%;
    height: 244px;
    border: 1px dashed #bababa;
    cursor: pointer;
    padding: 20px;
    border-radius: 4px;
  }
  
  .stripes {
    width: 100%;
    height: 250px;
    cursor: pointer;
    border: solid;
    border-color: #C8C8C8;
    background-image: repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px);
    -webkit-animation: progress 2s linear infinite !important;
    -moz-animation: progress 2s linear infinite !important;
    animation: progress 2s linear infinite !important;
    background-size: 150% 100%;
  }
  
  .rejectStripes {
    width: 100%;
    height: 250px;
    cursor: pointer;
    border: solid;
    border-color: #C8C8C8;
    background-image: repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px);
    -webkit-animation: progress 2s linear infinite !important;
    -moz-animation: progress 2s linear infinite !important;
    animation: progress 2s linear infinite !important;
    background-size: 150% 100%;
  }
  
  .fileIconImg {
    color: #d0d0d0 !important;
  }
  
  .smallPreviewImg {
    height: 237px !important;
    width: initial !important;
    max-width: 100%;
  }
  
  @-webkit-keyframes progress {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -75px 0;
    }
  }
  
  @-moz-keyframes progress {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -75px 0;
    }
  }
  
  @-ms-keyframes progress {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -75px 0;
    }
  }
  
  @keyframes progress {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -70px 0;
    }
  }
  
  .imageContainer {
    color: #768192;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms !important;
    box-sizing: border-box;
    -webkit-tap-highlight-color: #768192;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px !important;
    border-radius: 2px;
    z-index: 5;
    overflow: hidden;
    text-align: center;
    height: 244px;
    position: relative;
    border: 4px solid #fff;
    background: #909090;
    border-radius: 6px;
  }
  
  .imageContainer:hover .smallPreviewImg {
    opacity: 0.7;
  }
  
  .imageContainer:hover .middle {
    opacity: 1;
  }
  
  .imageContainer:hover .middleBigPic {
    opacity: 1;
  }
  .more_imagecontainer{
    width: 125px !important;
    height: 100px !important;
    margin: 11px !important;
    .smallPreviewImg{
      height: 100px !important;
    }
  }
  
  .removeBtn {
    color: white;
    z-index: 3;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 20
  }
  
  .dropzone_row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    flex: 0 1 auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
  
  .imgWrap {
    background: #e2e2e2;
  }
  
  .fileWrap {
    background: #e2e2e2;
    color: #969696;
  }
  
  .downloadBtn {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .images{
    border: 1px solid rgb(144, 140, 140);
    margin: 20px 5px !important;
  }
  .nav-tabs{
    justify-content: center !important;
    font-size: 24px !important;
    a{
      color: #2eb85c;
    }
  }