.default-0{
  position: relative;
  // top: 50%;
  // left:50%

}
.default .loader-container {
    width: 29px;
    height: 26px;
  }
  .default .ball {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 15%;
    height: 50%;
    background-color: transparent;
    transform-origin: bottom center;
    overflow: visible;
  }
  .default .ball:nth-of-type(2) {
    transform: translate(-50%, 0) rotate(30deg);
  }
  .default .ball:nth-of-type(3) {
    transform: translate(-50%, 0) rotate(60deg);
  }
  .default .ball:nth-of-type(4) {
    transform: translate(-50%, 0) rotate(90deg);
  }
  .default .ball:nth-of-type(5) {
    transform: translate(-50%, 0) rotate(120deg);
  }
  .default .ball:nth-of-type(6) {
    transform: translate(-50%, 0) rotate(150deg);
  }
  .default .ball:nth-of-type(7) {
    transform: translate(-50%, 0) rotate(180deg);
  }
  .default .ball:nth-of-type(8) {
    transform: translate(-50%, 0) rotate(210deg);
  }
  .default .ball:nth-of-type(9) {
    transform: translate(-50%, 0) rotate(240deg);
  }
  .default .ball:nth-of-type(10) {
    transform: translate(-50%, 0) rotate(270deg);
  }
  .default .ball:nth-of-type(11) {
    transform: translate(-50%, 0) rotate(300deg);
  }
  .default .ball:nth-of-type(12) {
    transform: translate(-50%, 0) rotate(330deg);
  }
  .default-01 .ball::before {
    top: 0;
    left: 0;
    width: 80%;
    padding-bottom: 80%;
    border-radius: 50%;
    background-color:  rgba(45,124,229,30%);
    transform: translateZ(0);
    animation: default1 1s infinite linear;
  }
  .default-01 .ball:nth-of-type(2)::before {
    animation-delay: 0.1s;
  }
  .default-01 .ball:nth-of-type(3)::before {
    animation-delay: 0.2s;
  }
  .default-01 .ball:nth-of-type(4)::before {
    animation-delay: 0.3s;
  }
  .default-01 .ball:nth-of-type(5)::before {
    animation-delay: 0.4s;
  }
  .default-01 .ball:nth-of-type(6)::before {
    animation-delay: 0.5s;
  }
  .default-01 .ball:nth-of-type(7)::before {
    animation-delay: 0.6s;
  }
  .default-01 .ball:nth-of-type(8)::before {
    animation-delay: 0.7s;
  }
  .default-01 .ball:nth-of-type(9)::before {
    animation-delay: 0.8s;
  }
  .default-01 .ball:nth-of-type(10)::before {
    animation-delay: 0.9s;
  }
  .default-01 .ball:nth-of-type(11)::before {
    animation-delay: 1s;
  }
  .default-01 .ball:nth-of-type(12)::before {
    animation-delay: 1.1s;
  }
  @keyframes default1 {
    33% {
      transform: scale(1.6) translateZ(0);
      background-color: #2d7ce5;
    }
    66% {
      transform: scale(1, 1) translateZ(0);
      background-color:  rgba(45,124,229,70%);
    }
    100% {
      transform: scale(1, 1) translateZ(0);
      background-color:  rgba(45,124,229,40%);
    }
  }
  /* loader */
// .loader {
//     height: 200px;
//   }
    .loader-container {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }
      .ball {
        position: absolute;
      }
  
  .loader ::before,
  .loader ::after {
    position: absolute;
    content: "";
    box-sizing: border-box;
  }
