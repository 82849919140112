//plugin scroll style

.TrackY {
  width: 7px !important;
  background-color: transparent !important;

  .ScrollbarsCustom-ThumbY {
    background-color: rgba(#748194, 0.3) !important;
  }
}

.TrackX {
  height: 7px !important;
  background-color: transparent !important;

  .ScrollbarsCustom-ThumbX {
    background-color: rgba(#748194, 0.3) !important;
  }
}

.contacts-list {
  .ScrollbarsCustom-Wrapper {
    right: 0 !important;
  }
}

//navbar scroll bar style
.scrollbar {
  scrollbar-color: rgba(#748194, 0.3) transparent;
  scrollbar-width: thin;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(#748194, 0.3);
  }
}
