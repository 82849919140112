@media (hover: hover), not all {
    .c-sidebar .c-sidebar-nav-link:hover,
    .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
        color: #fff;
        background: #303c54 !important;
    }
}
.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    color: #fff;
    background: rgb(48 65 92) !important;
}
.fs--1 {
    font-size: 1rem;
}
.p-5-5 {
    padding: 5px 5px !important;
}
.fs--0 {
    font-size: 0.875rem;
}

// blog-manager

.blogImage {
    width: 100%;
    height: 350px;
    border-radius: 1%;
}
.blog_image_badge {
    position: absolute;
    right: 21px;
    top: 7px;
}
.w-max {
    width: max-content;
}


.bg-bg-yellow-light {
    background: #FFBE6B;
}
.bg-bg-purple {
    background: #7e0b7e;
}
.bg-bg-blue-light {
    background: #79C6FF;
}
.bg-bg-dark-blue-light{
    background: #3366BB;
}
.bg-orange {
    background: #ff9700;
}
.bg-bg-orange {
    background: #ff9700;
}
.bg-blue {
    background: #0063b2;
}
.bg-bg-blue {
    background: #0063b2;
}
.bg-green {
    background: #249e00;
}
.bg-bg-green {
    background: #249e00;
}
.bg-light-green {
    background: #63c0b8;
}
.bg-light-pink {
    background: #fa7aad;
}
.bg-bg-light-pink {
    background: #fa7aad;
}
.bg-dark-red {
    background: #d94845;
}
.bg-bg-dark-red {
    background: #d94845;
}
.bg-bg-pink {
    background: #ff2a6b;
}
.bg-bg-light-green {
    background: #55cc32ed;
    border: 1px solid #3fdf0e;
    border-color: #1e6508 !important;
}
.bg-bg-light-red {
    background: #d94845;
    border: 1px solid #d94845;
    border-color: #e70704 !important;
}
.cursor-pointer {
    cursor: pointer;
}


// Primary Color

.btn-primary {
    background-color: #01a452 !important;
    border-color: #01a452;
}
.text-primary{
    color:#01a452 !important;
}
.bg-primary{
    background-color: #01a452 !important;
}
.border-primary{
    border-color: #01a452 !important;
} 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// .metrics_widgets{
//     .card-body{
//         .col{
//             width:50%;
//         }
//     }
// }